import { tailwind } from '@theme-ui/presets'

const sharedBodyFontSize = {
  fontSize: ['1.2rem', 3, 3, 3],
}

export default {
  ...tailwind,
  initialColorMode: 'dark',
  // initialColorModeName: 'light',
  // useColorSchemeMediaQuery: true,
  // useCustomProperties: true,
  // breakpoints: ['600px', '900px', '1000px', '1800px'],
  breakpoints: [ "640px", "768px", "1024px", "1180px"],
  colors: {
    ...tailwind.colors,
    background: '#666ACE',
    // background: '#647FD4',
    // background: '#0B1821',
    text: '#FFFFFE',
    // primary: '#FFE6B3',
    primary: '#FCD685',
    // primary: '#708BED',
    // primaryHover: '#54BED9',
    primaryHover: '#FFFFFE',
    modes: {
      light: {
        background: '#7093df',
        text: '#190818',
        primary: '#210c2c',
        primaryHover: '#5c2776',
      },
    },
  },
  space: [
    0,
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '5rem',
    '6rem',
    '8rem',
    '10rem',
    '12rem',
    '14rem',
    '16rem',
  ],
  sizes: {
    ...tailwind.sizes,
    container: '1200px'
  },
  layout: {
    container: {
      p: [0, 4, 7],
      pt: 4,
    },
  },
  fonts: {
    body: '"Muli", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    heading: '"Muli", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  fontWeights: {
    ...tailwind.fontWeights,
    heading: 400,
  },
  text: {
    heading: {
      fontSize: ['1.6rem', '3rem', '3.3rem', '3.5rem'],
      ml: [5, 0],
    },
    subHeading: {
      variant: 'text.heading',
      fontSize: ['1.6rem', '2.5rem', '3rem', '3.3rem'],
    },
    body: {
      ...sharedBodyFontSize,
    },
    blurb: {
      ...sharedBodyFontSize,
      maxWidth: ['100%', 'md', 'md', 'lg'],
      mt: [4, 7],
      ml: [8, 10],
      mr: [7, 4, 0],
    },
  },
  forms: {
    label: {
      variant: 'text.body',
      mb: 6,
    },
    input: {
      ...sharedBodyFontSize,
      fontFamily: 'body',
      height: '3.5rem',
      color: 'dark',
      backgroundColor: 'text',
      borderRadius: 'none',
      borderStyle: 'none',
      pt: '0.625rem',
      pb: '0.625rem',
      pl: 6,
      pr: 6,
      mb: 6,
      transition: 'all 0.3s ease-in-out',
      '&:focus': {
        color: 'text',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        outline: 'none',
      },
    },
    textarea: {
      variant: 'forms.input',
    },
  },
  styles: {
    ...tailwind.styles,
    a: {
      color: 'primary',
      transition: 'all 0.2s ease-in-out',
      ":hover": {
        color: 'primaryHover',
        transform: 'translateY(-8%)',
      },
    },
  },
  buttons: {
    primary: {
      backgroundColor: 'transparent',
    },
  },
  gradients: {
    blue: {
      backgroundImage: t => `linear-gradient(45deg, ${t.colors.indigo[3]}, ${t.colors.indigo[5]})`,
    },
    orange: {
      backgroundImage: t => `linear-gradient(225deg, ${t.colors.orange[3]}, ${t.colors.orange[5]})`,
    },
    pink: {
      backgroundImage: t => `linear-gradient(135deg, ${t.colors.pink[4]}, ${t.colors.pink[6]})`,
    },
    purple: {
      backgroundImage: t => `linear-gradient(135deg, ${t.colors.purple[6]}, ${t.colors.purple[3]})`,
    },
    gray: {
      backgroundImage: t => `linear-gradient(135deg, ${t.colors.gray[5]}, ${t.colors.gray[7]})`,
    },
    black: {
      backgroundImage: t => `linear-gradient(135deg, ${t.colors.gray[7]}, ${t.colors.gray[9]})`,
    },
    red: {
      backgroundImage: t => `linear-gradient(225deg, ${t.colors.red[6]}, ${t.colors.red[3]})`,
    },
    yellow: {
      backgroundImage: t => `linear-gradient(180deg, ${t.colors.yellow[5]}, ${t.colors.yellow[6]})`,
    },
    green: {
      backgroundImage: t => `linear-gradient(225deg, ${t.colors.green[3]}, ${t.colors.green[5]})`,
    },
    indigo: {
      backgroundImage: t => `linear-gradient(72deg, ${t.colors.indigo[7]}, ${t.colors.indigo[5]})`,
    },
    teal: {
      backgroundImage: t => `linear-gradient(72deg, ${t.colors.teal[7]}, ${t.colors.teal[5]})`,
    },
    darkIndigo: {
      backgroundImage: t => `linear-gradient(135deg, ${t.colors.indigo[8]}, ${t.colors.indigo[6]})`,
    },
  },
}
